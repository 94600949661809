import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTField SetValue:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc SaveViewerOptimized:opts:]</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFDocGenerator GenerateDottedPaperDoc:height:dot_spacing:dot_size:red:green:blue:background_red:background_green:background_blue:]</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFDocGenerator GenerateIsometricDottedPaperDoc:height:dot_spacing:dot_size:red:green:blue:background_red:background_green:background_blue:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl PrepareWords:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl WereWordsPrepared:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl IsThereTextInRect:y1:x2:y2:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl PrepareAnnotsForMouse:distance_threshold:minimum_line_weight:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl WereAnnotsForMousePrepared:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetAnnotTypeUnder:y:]</div>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/PTAddPagesManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyle</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleBlank</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleLined</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleGrid</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleGraph</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleMusic</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleDotted</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateStyleIsometricDotted</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesManager initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesManager showPageTemplateViewController]</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesManager showImagePickerController]</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesManager showDocumentPickerViewController]</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.pageNumber</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.presentingViewController</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.barButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.sourceView</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.whitePageColor</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.yellowPageColor</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesManager.blueprintPageColor</div>
<div class="difference"><span class="status added">Added</span> +[PTAddPagesManager createDocWithTemplate:pageSize:backgroundColor:pageCount:portrait:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAddPagesViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesViewController initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.addPagesManager</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.items</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.addBlankPagesButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.addImagePageButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.addDocumentPagesButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesViewController showPageTemplateViewController:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesViewController showImagePickerController:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesViewController showDocumentPickerViewController:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationPasteboard.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationPasteboard</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationPasteboard.defaultPasteboard</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationPasteboard.annotations</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPasteboard copyAnnotations:withPDFViewCtrl:completion:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPasteboard pasteAnnotationsOnPageNumber:atPagePoint:withPDFViewCtrl:completion:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPasteboard pasteAnnotationsOnPageNumber:atPagePoint:withToolManager:completion:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPasteboard clear]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDigSigViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDigSigViewController.allowPhotoPicker</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.exportButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.addPagesButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.exportButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.addPagesButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.addPagesViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController initWithCoder:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewSettings.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewSettings</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>NSCoding</td></tr>
<tr><th>To</th><td>NSCoding, NSCopying</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFormFillTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFormFillTool.textFieldContentsFitBounds</div>
<div class="difference"><span class="status added">Added</span> PTFormFillTool.useTextViewAppearance</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeHandCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTFreeHandCreate createAnnotation]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTImageCropTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTImageCropTool</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTImageStampAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationOptions.cropEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController.selectedViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, assign, nullable) UIViewController *selectedViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) UIViewController *selectedViewController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPageTemplateViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTPageTemplateViewControllerDelegate pageTemplateViewController:createdDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPageTemplateViewControllerDelegate pageTemplateViewControllerDidCancel:]</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTPageTemplateViewController initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.pageTemplates</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.pageSizes</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.pageColors</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.defaultPageSize</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.pageNumber</div>
<div class="difference"><span class="status added">Added</span> PTPageTemplateViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTool flattenAnnotations:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.freehandHighlightAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl DocLock:withBlock:error:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl DocLockReadWithBlock:error:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTPDFViewCtrlToolDelegate, PTToolEvents, PTToolOptionsDelegate</td></tr>
<tr><th>To</th><td>PTOverridable, PTPDFViewCtrlToolDelegate, PTToolEvents, PTToolOptionsDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> +[PTToolsSettingsManager sharedManager]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (nonnull PTToolsSettingsManager *)sharedManager</td></tr>
<tr><th>To</th><td class="declaration">@property (class, nonatomic, readonly, strong, nonnull) PTToolsSettingsManager *sharedManager</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolsSettingsManager.toolsDefaultSettings</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, retain, nonnull) NSArray *toolsDefaultSettings</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, retain, nonnull) NSArray&lt;NSDictionary&lt;NSString *,id&gt; *&gt; *toolsDefaultSettings</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}